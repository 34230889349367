import React from 'react';

const Whitepaper = () => (
  <div>
    <h1>OTC.mx: Trustless, Peer-to-Peer Derivative Trading on Ethereum</h1>
    <p>Version 0.2.1: November 6, 2019</p>
    <h2>Introduction</h2>
      <p>We present a protocol for trustless, peer-to-peer ERC20 derivative trading across arbitrary token pairs. The protocol supports both options and forwards, or over the counter futures. We then present a minimal application to interact with the protocol, allowing users to create option contracts and share them with a hyperlink. This application's functionality is reminiscent of that of <a href="https://trader.airswap.io/">AirSwap Trader</a>, but allows users to trade derivatives rather than tokens. At this time, the application's interface only supports forwards and call options, but an analogous put option UI can easily be constructed. Finally, we outline next steps for the application.</p>

    <h2>Functionality</h2>
      <p>The protocol's options offerings support American and European options, as well as <i>silent</i> options that do not reveal their strike price until exercised. It also supports <i>tokenized</i> options, which allow users to trade claims on options as well as the underlying collateral in the event that the option is not exercised. Since the token pairs are arbitrary, the protocol can support both call and put options. While the options offering somewhat resembles the <a href="https://whitepaper.dydx.exchange/">dYdX Options Protocol</a>, the OTC.mx protocol is the first options protocol to release public, open-source smart contracts.</p>
      <p>The protocol's forwards offerings support standard forwards, as well as <i>managed</i> forwards, which allow users to collateralize entry into a contract against the payout of another forwards contract that has not yet matured.</p>
    <h2>Protocol</h2>
      <h3>Options</h3>
        <p>OTC.mx contains a contract suite for trading decentralized options, which grant the buyer the right, but not the obligation, to purchase an arbitrary token at a predetermined price, in a base token, within some window of time.</p>
        <h4>Options</h4>
          <p>OTC.mx's smart contract suite includes an <code>OptionFactory</code> contract, handling the creation of a new option, and an <code>Option</code> contract, handling the rest of the option's lifecycle.</p>
          <p>The <code>OptionFactory</code> contract contains the method for the creation of an option contract, <code>create_option</code>. This method takes as arguments the parameters of the new contract: <code>issuer</code> and <code>buyer</code>, the addresses of the contract's parties; <code>base_addr</code> and <code>asset_addr</code>, the addresses of the base and asset tokens; <code>fee</code> the fee charged by the issuer to the buyer for issuing the contract; <code>strike_price_base</code> and <code>strike_price_quote</code>, a pair specifying the strike price, such that <i>(<code>strike_price_base</code> * asset_volume = <code>strike_price_quote</code> * base_volume)</i>, where <i>base_volume</i> and <i>asset_volume</i> represent the volumes of the base and asset tokens traded; <code>volume</code>, the total volume of the asset for which the buyer has bought the option; and <code>maturity_time</code> and <code>expiry_time</code>, the Unix timestamps for the beginning and end of the period in which the option can be exercised, with the relationship between these two parameters and the time at which the option was issued determining if the option is American or European. The method returns the address of the newly created option contract.</p>
          <p>The <code>Option</code> contract specifies the majority of the option's logic and lifecycle. It contains five user-facing methods for the best-case lifecycle: <code>collateralize()</code>, which transfers the required asset tokens to the contract and and marks the option as collateralized; <code>pay_fee()</code>, which lets the user pay the fee to the issuer and marks the option as active; <code>exercise_from_base(base_vol_exercised)</code> and <code>exercise_from_asset(asset_vol_exercised)</code>, which allow the buyer to exercise the option specifying either the number of base tokens to sell or asset tokens to buy, and can be called repeatedly until the initial <code>volume</code> of asset tokens is depleted or the contract is expired; and <code>expire()</code> which marks the contract as expired and refunds the issuer on any unclaimed asset tokens. Additionally, it contains a convenience function, <code>get_info()</code>, that returns the contract's parameters. Finally, it contains two functions to transfer the roles of issuer and buyer, <code>transfer_issuer</code> and <code>transfer_buyer</code></p>
        <h4>Silent Options</h4>
          <p>OTC.mx also offers silent options, which do not reveal the strike price until exercised. This functionality is useful for issuers who want to tailor their prices to different buyers' willingness to pay, buyers who wish to exploit market inefficiencies, and traders who wish to perform large trades without moving the market. From an implementation perspective, the silent option protocol functions nearly identically to the option protocol. However, instead of specifying the base-quote strike price pair on initialization, only the hashes of the base-quote strike price pair are committed on initialization, with additional salt to prevent brute-forcing. This conceals the strike price until the option is exercised, with the transaction only going through the if the hashes of the revealed values are equal to the committed values.</p>
        <h4>Tokenized Options</h4>
          <p>The final option products that OTC.mx supports are tokenized options, which allow users to distribute the potential risk and reward from an options contract. Tokenized options create two ERC20 tokens, one representing an option and the other representing a claim on the collateral in the case the option is not exercised. These tokens can then be burnt to receive the right to exerise the option or redeem the collateral, respectively. Unlike the issuer of standard options and silent options, holders of the collateral claim tokens do not receive the funds sent by the exerciser of the option immediately, but instead must wait until the option has expired.</p>
      <h3>Forwards</h3>
        <p>OTC.mx also contains a contract suite for trading decentralized forwards, which grant the buyer the right and obligation to purchase an arbitrary token at a predetermined price, in a base token, at a future point in time.</p>
        <h4>Forwards</h4>
          <p>OTC.mx's basic forwards function similarly to its options offering. The contract suite includes a <code>ForwardFactory</code> contract, handling the creation of the forward, and a <code>Forward</code> contract, handling the rest of the forward's lifecycle.</p>
          <p>As is the case with the options suite, the <code>ForwardFactory</code> contract contains a function <code>create_forward</code>, which creates the forward. The <code>Forward</code> contract contains three user-facing methods for the best-case lifecycle: <code>collateralize()</code>, which transfers the required asset tokens to the contract and and marks the option as collateralized; <code>activate()</code>, which allows the buyer to deposit their base collateral and marks the contract as active; and <code>settle()</code>, which settles the forwards contract and marks it as expired. Like the <code>Option</code> contract, the <code>Forward</code> contract also contains a function <code>expire()</code>, which marks the contract as expired and can be used to abort the contract if the buyer fails to activate the forward. It also contains a convenience function <code>get_info()</code> that returns the contract's parameters, as well as two functions to transfer the roles of issuer and buyer, <code>transfer_issuer</code> and <code>transfer_buyer</code>.</p>
        <h4>Managed Forwards</h4>
          <p>OTC.mx also supports managed forwards, which can be collateralized or activated against the future payout of another forward. This suite includes four contracts: <code>ManagedForwardFactory</code>, <code>PortfolioFactory</code>, <code>ManagedForward</code>, and <code>Portfolio</code>.</p>
          <p><code>ManagedForwardFactory</code> and <code>PortfolioFactory</code> are simple factory contracts, responsible for the creation of new <code>ManagedForward</code>, and <code>Portfolio</code> instances, using <code>create_managed_forward</code> and <code>create_portfolio</code>, respectively. They also allow external contracts to verify that they are the original creator of a specified instance, using <code>get_created_forward</code> and <code>get_created_portfolio</code>, respectively.</p>
          <p><code>Portfolio</code> contains the logic managing all the user's managed forwards on one currency pair. It has five user-facing functions: <code>add_managed_forward</code>, which adds an existing managed forward to the portfolio to be collateralized or activated from its funds, or other contracts in the portfolio; <code>deposit</code> and <code>withdraw</code>, which allow the portfolio's owner to add or remove funds from the portfolio;
          <code>get_volume_available(address token_addr)</code>, which checks the portfolio's balance of a given token that has not been used as collateral; and <code>get_forward_index(address forward_address)</code>, which returns an integer above zero if the forward has been added to this portfolio.</p>
          <p><code>ManagedForward</code> contains the logic for the lifecycle of a managed forward, largely resembling <code>Forward</code>. It offers five additional lifecycle functions: <code>collateralize_from_portfolio</code> and <code>activate_from_portfolio</code>, which respectively collateralize or activate the contract from excess funds in the caller's portfolio; <code>collateralize_from_portfolio</code> and <code>activate_from_portfolio</code>, which respectively collateralize or activate the contract from the payout of another contract with an earlier maturity date, falling back onto excess funds in the caller's portfolio; and <code>force_settle</code>, which recursively settles every contract on which the settlement of this contract depends. It also offers one additional convenience function, <code>get_portfolio_info</code>, which returns information on the contract's portfolio.</p>
    <h2>Application</h2>
      <p>The application, available at <a href="/">https://otc.mx</a>, allows users to interact with the protocol's smart contract suite, and supports call options, silent call options, tokenized call options, forwards, and managed forwards. Because it opts for simplicity over flexibility, it makes several choices for the user: token transfers are handled by the application automatically where needed; and upon the creation of a derivative, the contract is immediately collateralized. The application allows users to create a contract, share the contract they created by sending a link, and perform all further operations on that contract from that link, allowing for simple over the counter derivatives trading.</p>
    <h2>Next Steps</h2>
      <p>The application will soon support put options and silent put options, which will be simply be a wrapper for users to interact with the existing contracts using <code>exercise_from_base</code>. The application will also undergo various UI improvements.</p>
  </div>
);

export default Whitepaper;
